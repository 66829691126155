import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { breakpointLarge } from '../../styles/breakpoints';
import ContentContainer from '../UI/ContentContainer';
import Section from '../UI/Section';

const PartnersStyled = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  h2 {
    text-align: center;
  }
`;
const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items:center;
  justify-content: center;
  padding-left: 2.75rem;
  padding-right: 2.75rem;
  a {
    display: flex;
    align-items:center;
    justify-content: center;
    display: block;
    width: 3rem;
    height: 3rem;
    margin: 0.125rem;
    @media (min-width: ${breakpointLarge}) {
      width: 10rem;
      height: 10rem;
      margin: 0.5rem;
    }
  }
`;

const Partners = ({ title, logos, preview }) => {

  return (
    <PartnersStyled>
      <ContentContainer>
        <h2>{title}</h2>
        <Logos>
          {console.log(logos)}
          {logos.map(({ link, logo, name }) => {
            let img = preview ? <img src={logo} /> : <Img fluid={logo.childImageSharp.fluid} alt={name} />
            return (
              <a href={link} target="_blank" rel="noopener noreferrer" key={name}>
                {img}
              </a>
            )
          })}
        </Logos>
      </ContentContainer>
    </PartnersStyled>
  );
};

Partners.propTypes = {
  title: PropTypes.string,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.object,
      link: PropTypes.string,
    })
  ),
};

export default Partners;
