import styled from 'styled-components';
import { breakpointLarge } from '../../styles/breakpoints';
import SVGColor from './SVGColor';

const ButtonPurple = styled.a`
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
  padding: 0.7rem 1.75rem;
  color: ${p => p.theme.purple};
  background-color: ${p => p.theme.purpleLight};
  border: 1px solid ${p => p.theme.purple};
  border-radius: 3.5rem;
  position: relative;
  transition: all 0.25s ease;
  &:hover {
    background-color: ${p => p.theme.purpleLight};
    ${SVGColor(props => props.theme.purpleLight)}
    border-color: ${p => p.theme.purpleLight};
  }
  @media (min-width: ${breakpointLarge}) {
    padding: 0.8rem 2.5rem;
  }
`;

export default ButtonPurple;
