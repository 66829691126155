import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { breakpointLarge } from '../../styles/breakpoints';
import Section from '../UI/Section';

const HowItWorksStyled = styled(Section)`
  background-color: ${props => props.theme.grayLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${breakpointLarge}) {
    padding-top: 4rem;
  }
`;
const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 1.25rem 0 1.5rem;
  max-width: 26rem;
  @media (min-width: ${breakpointLarge}) {
    max-width: 60rem;
  }
`;
const Item = styled.div`
  flex: 0 0 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.5rem 1.75rem;
  @media (min-width: ${breakpointLarge}) {
    flex: 0 0 10rem;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  height: 5.5rem;
  margin-bottom: 0.5rem;
  background-color: #fff;
  border-radius: 50%;
  @media (min-width: ${breakpointLarge}) {
    width: 9rem;
    height: 9rem;
    margin-bottom: 1.5rem;
  }
`;
const Description = styled.div`
  text-align: center;
  line-height: 1.3;
`;
const IconSeccion = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
`;

const HowItWorks = ({ heading, items, preview }) => {

  return (
    <HowItWorksStyled>
      <h2>{heading}</h2>
      <Items>
        {items.map(item => {

          let img = preview ? <img src={item.icon} /> : <Img fixed={item.icon.childImageSharp.fixed} />

          return (
            <Item key={item.text}>
              <IconContainer>
                <IconSeccion>
                  {img}
                </IconSeccion>
              </IconContainer>
              <Description>{item.text}</Description>
            </Item>
          )

        })}
      </Items>
    </HowItWorksStyled>
  )

};

HowItWorks.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.object,
    })
  ),
};

export default HowItWorks;
