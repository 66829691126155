import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Cookies from '../components/Cookies';
import Hero from '../components/Home/Hero';
import HowItWorks from '../components/Home/HowItWorks';
import Partners from '../components/Home/Partners';
import Promise from '../components/Home/Promise';
import Layout from '../components/Layout';

export const IndexPageTemplate = ({
  title,
  howItWorks,
  mainpitch,
  partners,
  preview,
}) => (
  <>
    <Cookies/>
    <Hero title={title} />
    <HowItWorks
      preview={preview}
      heading={howItWorks.heading}
      items={howItWorks.items}
    />
    <Promise
      title={mainpitch.title}
      description={mainpitch.description}
      items={mainpitch.items}
    />
    <Partners preview={preview} title={partners.title} logos={partners.logos} />
  </>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  howItWorks: PropTypes.object,
  mainpitch: PropTypes.object,
  partners: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout isHome>
      <IndexPageTemplate
        title={frontmatter.title}
        howItWorks={frontmatter.howItWorks}
        mainpitch={frontmatter.mainpitch}
        partners={frontmatter.partners}
        preview={false}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

//item.icon.childImageSharp.fixed
export const IndexPageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        howItWorks {
          heading
          items {
            text
            icon {
              childImageSharp {
                fixed(quality: 64) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        mainpitch {
          title
          description
          items {
            text
          }
        }
        partners {
          title
          logos {
            name
            link
            logo {
              childImageSharp {
                fluid(maxWidth: 168, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
