/* eslint-disable jsx-a11y/accessible-emoji */
import { Link } from 'gatsby';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import headerPath from '../../img/header-path.svg';
import Apply from '../../img/icons/apply-for-credit.svg';
import Pay from '../../img/icons/pay-credit.svg';
import Simulate from '../../img/icons/simulate-credit.svg';
import Woman from '../../img/icons/woman.svg';
import logo from '../../img/logo-juancho-te-presta-vertical.webp';
import logoMobil from '../../img/logo-juancho-te-presta-vertical_mobil.webp';
import { breakpointLarge } from '../../styles/breakpoints';
import SVGColor from '../UI/SVGColor';

const HeroStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.75rem;
  background-color: #fff;
  @media (min-width: ${breakpointLarge}) {
    padding-bottom: 4rem;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.yellow};
  background-image: url(${headerPath});
  background-repeat: no-repeat;
  background-position: center 101%;
  background-size: contain;
  width: 100%;
  margin-bottom: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height:150px;
  @media (min-width: ${breakpointLarge}) {
   height:210px;
  }
  @media (min-width: 1300px) {
    height:270px;
  }
  @media (min-width: 1700px) {
    height:320px;
  }
  @media (min-width: 2000px) {
    height:380px;
  }
  @media (min-width: 2400px) {
    height:480px;
  }
  @media (min-width: 3000px) {
    height:580px;
  }
`;
const ImgLogo = styled.img`
  position:absolute;
  max-width: 300px;
  bottom:-210px;
  @media (min-width: ${breakpointLarge}) {
    height: 330px;
    max-width: 471px;
    bottom:-300px;
  }
  @media (min-width: 1500px) {
    bottom:-280px;
  }
  @media (min-width: 1800px) {
    bottom:-250px;
  }
`;
const Title = styled.h2`
  margin: 0.5rem 1rem 1rem 1rem;
  text-align: center;
  @media (min-width: ${breakpointLarge}) {
    margin: 4.5rem 1rem 1rem 1rem;
  }
`;
const Buttons = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  margin-top: 0.125rem;
  & > a {
    margin: 0.3125rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 3.125rem;
    padding-left: 1.125rem;
    svg {
      margin-right: 0.5rem;
      min-width: 1.75rem;
    }
  }
  @media (min-width: ${breakpointLarge}) {
    flex-direction: row;
    width: 100%;
    max-width: 1250px;
    & > a {
      justify-content: flex-start;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      flex: 1;
    }
  }
`;

const Btn = styled.a`
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  padding: 1rem 1.55rem;
  color: ${p => p.theme.blueDark};
  background-color: #fff;
  border: 1px solid ${p => p.theme.blueDark};
  ${SVGColor(props => props.theme.blueDark)}
  border-radius: 3.5rem;
  position: relative;
  transition: all 0.25s ease;
  &:hover {
    background-color: ${props => props.theme.yellow};
    color: black;
    ${SVGColor("black")}
    border-color: ${p => p.theme.yellow};
  }
  @media (min-width: ${breakpointLarge}) {
    padding: 1.5rem 2rem;
  }
`;
const TextContent = styled.div`
  display:flex;
  text-align:center;
  align-items:center;
  justify-content:center;
  width:100%;
  padding-top:2px;
  @media (min-width: ${breakpointLarge}) {
    padding-top:3px;
  }
`

const Hero = ({ title }) => {

  const [utmCookie, setUtm] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    let utm = Cookie.get('utm') ? Cookie.get('utm') : '';
    setUtm(utm);
    const handleResize = () => {
      setIsMobile(window.innerWidth < parseInt(breakpointLarge));
    };

    // Verificar el tamaño de la pantalla inicialmente
    handleResize();

    // Escuchar cambios en el tamaño de la pantalla
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <HeroStyled>
      <ImageContainer>
      <ImgLogo src={isMobile ? logoMobil : logo} alt="Juancho te presta" />
      </ImageContainer>
      <Title>{title}</Title>

      <Buttons>
        <Btn href={`${process.env.GATSBY_JTP_APP}/signup${utmCookie}`}>
          <Apply />
          <TextContent>
            Solicita tu crédito
          </TextContent>
        </Btn>
        <Btn href={`${process.env.GATSBY_JTP_APP}/simulate${utmCookie}`}>
          <Simulate />
          <TextContent>
            Simulador de crédito
          </TextContent>
        </Btn>
        <Btn as={Link} to="/privilegios/">
          <Woman />
          <TextContent>
            ¿Por qué Juancho?
          </TextContent>
        </Btn>

        <Btn href={`${process.env.GATSBY_JTP_APP}/login${utmCookie}`}>
          <Pay />
          <TextContent>
            Paga tu cuota
          </TextContent>

        </Btn>

      </Buttons>
    </HeroStyled>
  );
};

Hero.propTypes = {
  title: PropTypes.string,
};

export default Hero;
